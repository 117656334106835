import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from 'vuex-persist';

Vue.use(Vuex);

import settlement from "./modules/settlement";
import notifications from "./modules/notifications";
import Categories from "./modules/category";
import LoyaltyFaq from "./modules/loyaltyFaq";

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: (state) => ({
    LoyaltyFaq: state.LoyaltyFaq,
  })
});

export default new Vuex.Store({
  state: {
    loading: false,
    provider: {
      // providerID: '615dd8e6c6368e0f69818b1d',
      // token: 'Ya1mn8FH6CwZjrVLyM0zfUMAks2eEqv5',
      // accessToken: '615dd8e6c6368e0f69818b1d####Ya1mn8FH6CwZjrVLyM0zfUMAks2eEqv5',

      
      // testing beta
      providerID: null,
      token: null,
      accessToken: null,
    },
    language: "es",
    providerDetails: {
      debt: null,
      balance: null,
      covenant: null,
      covenantBalance: null,
    },
  },
  getters: {},
  mutations: {
    SET_LOADING: (state, value) => (state.loading = value),
    SET_PROVIDER: (state, value) => (state.provider = value),
    SET_LANGUAGE: (state, value) => (state.language = value),
    SET_PROVIDER_DETAILS: (state, value) => (state.providerDetails = value),
  },
  actions: {
    setLoading({ commit }, payload) {
      commit("SET_LOADING", payload);
    },
    setProvider({ commit }, payload) {
      commit("SET_PROVIDER", payload);
    },
    setLanguage({ commit }, payload) {
      commit("SET_LANGUAGE", payload);
    },
    setProviderDetails({ commit }, payload) {
      commit("SET_PROVIDER_DETAILS", payload);
    },
  },
  modules: {
    settlement,
    notifications,
    Categories,
    LoyaltyFaq,
  },
  plugins: [vuexLocal.plugin]
});
