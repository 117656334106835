const state = {
  loyaltyFaqState: {
    loyaltyFaq: null,
    date: null
  }
};

const mutations = {
  SET_LOYALTY_FAQ_STATE(state, loyaltyFaqState) {
    state.loyaltyFaqState = loyaltyFaqState;
  }
};

const actions = {
  setLoyaltyFaqState({ commit }, loyaltyFaqState) {
    commit('SET_LOYALTY_FAQ_STATE', loyaltyFaqState);
  }
};

const getters = {
  loyaltyFaqState: (state) => state.loyaltyFaqState
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
