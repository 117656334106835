export default [
  {
    path: "/ridery-program/camera-test",
    name: "rideryProgramTest",
    component: () =>
      import(/* webpackChunkName: "ridery-program-camera-test" */ "../TestView.vue"),
  },
  {
    path: "/ridery-program",
    name: "rideryProgram",
    component: () =>
      import(/* webpackChunkName: "ridery-program" */ "../RideryProgramView.vue"),
  },
  {
    path: "/ridery-program/virtual-inspection",
    name: "virtualInspection",
    component: () =>
      import(/* webpackChunkName: "ridery-program-virtual-inspection" */ "../VirtualInspectionView.vue"),
  },
  {
    path: "/ridery-program/virtual-inspection/frontal-picture",
    name: "virtualInspectionFrontalPicture",
    component: () =>
      import(/* webpackChunkName: "ridery-program-virtual-inspection-frontal-picture" */ "../VirtualInspectionFrontalPictureView.vue"),
  },
  {
    path: "/ridery-program/virtual-inspection/back-picture-car",
    name: "virtualInspectionBackPictureCar",
    component: () =>
      import(/* webpackChunkName: "ridery-program-virtual-inspection-back-picture-car" */ "../VirtualInspectionBackPictureCarView.vue"),
  },
  {
    path: "/ridery-program/virtual-inspection/back-picture-bike",
    name: "virtualInspectionBackPictureBike",
    component: () =>
      import(/* webpackChunkName: "ridery-program-virtual-inspection-back-picture-bike" */ "../VirtualInspectionBackPictureBikeView.vue"),
  },
  {
    path: "/ridery-program/virtual-inspection/laterals-picture",
    name: "virtualInspectionLateralsPicture",
    component: () =>
      import(/* webpackChunkName: "ridery-program-virtual-inspection-laterals-picture" */ "../VirtualInspectionLateralsPictureView.vue"),
  },
  {
    path: "/ridery-program/virtual-inspection/interior-picture",
    name: "virtualInspectionInteriorPicture",
    component: () =>
      import(/* webpackChunkName: "ridery-program-virtual-inspection-interior-picture" */ "../VirtualInspectionInteriorPictureView.vue"),
  },
  {
    path: "/ridery-program/virtual-inspection/in-revision",
    name: "virtualInspectionInRevision",
    component: () =>
      import(/* webpackChunkName: "ridery-program-virtual-inspection-in-revision" */ "../VirtualInspectionInRevisionView.vue"),
  },
  {
    path: "/ridery-program/virtual-inspection/quiz",
    name: "virtualInspectionQuiz",
    component: () =>
      import(/* webpackChunkName: "ridery-program-virtual-inspection-quiz" */ "../VirtualInspectionQuizView.vue"),
  },
  {
    path: "/ridery-program/virtual-inspection/status/",
    name: "virtualInspectionStatus",
    component: () =>
      import(/* webpackChunkName: "ridery-program-virtual-inspection-status" */ "../VirtualInspectionStatusView.vue"),
  },
  {
    path: "/ridery-program/video-interview",
    name: "videoInterview",
    component: () =>
      import(/* webpackChunkName: "ridery-program-video-interview" */ "../VideoInterviewView.vue"),
  },
  {
    path: "/ridery-program/video-interview/in-revision",
    name: "videoInterviewInRevision",
    component: () =>
      import(/* webpackChunkName: "ridery-program-video-interview-in-revision" */ "../VideoInterviewInRevisionView.vue"),
  },
  {
    path: "/ridery-program/video-interview/status",
    name: "videoInterviewStatus",
    component: () =>
      import(/* webpackChunkName: "ridery-program-video-interview-status" */ "../VideoInterviewStatusView.vue"),
  },
  {
    path: "/ridery-program/rcv",
    name: "rcv",
    component: () =>
      import(/* webpackChunkName: "ridery-program-rcv" */ "../RcvView.vue"),
  },
  {
    path: "/ridery-program/induction",
    name: "induction",
    component: () =>
      import(/* webpackChunkName: "ridery-program-induction" */ "../InductionView.vue"),
  },
  {
    path: "/ridery-program/induction/success",
    name: "inductionSuccess",
    component: () =>
      import(/* webpackChunkName: "ridery-program-induction" */ "../InductionSuccessView.vue"),
  },
  {
    path: "/ridery-program/personal-inspection",
    name: "personalInspection",
    component: () =>
      import(/* webpackChunkName: "ridery-program-personal-inspection" */ "../PresencialInspectionView.vue"),
  },
];